
.personContainer{
    display:flex;
    justify-content: center;
    margin:30px
}

.personCard{
    position:relative;

}

.personCard .after{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 47%;
        border-radius:80% 70%/250px 60%  0px 0px;
        border:2px solid white;
        display: none;
        color: #FFF;
        display: block;
        opacity:1;
        background: linear-gradient(256.27deg, rgba(0, 119, 255, 0.3) 3.41%, rgba(0, 119, 255, 0.1) 98.25%);
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);

}



.personCard .after .team-details {
    position: absolute;
    bottom:50px;
    left: 55px;
    text-align:center
  }

  .personCard .after .text-details .textSize{
    font-size:10px;
    width:70%
}

.circleContainer{
    display:flex;
    justify-content: center;
    
}

.circleContainer .circleOutline{
    border:2px solid #0077FF;
    border-radius:50%;
    padding:20px 

}

.circleContainer .image{
    border-radius:50%
}


@media screen and (min-width:1024px){
    
.personContainer{
    margin:30px
}

.personCard .after .text-details .textSize{
    font-size:39px;
    width:70%
}

.circleContainer{
    display:flex;
    justify-content: center;
    margin:0px 30px 0px 30px
}

.personCard .after .team-details {
    position: absolute;
    bottom:50px;
    margin:auto;
    width:70%;
    text-align:center
  }

}

@media screen and (max-width:1023px){
    .personCard .after .text-details .textSize{
        font-size:10px;
        width:70%
    }

    .personCard .after .team-details {
        position: absolute;
        bottom:50px;
        margin:auto;
        width:70%;
        text-align:center
      }
}