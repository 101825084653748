
@media screen and (min-width:1023px){
.portfolioGrid{
    display:grid;
    grid-template-columns: auto auto auto auto;
    padding: 5px;
    width:80%;
    margin:auto
}

}

@media screen and (max-width:1023px){
    .portfolioGrid{
        display:block;
        padding: 10px;
        margin:50px
    }
}