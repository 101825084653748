.blogImageContainer{
    position: relative;
    text-align: center;
    color: white;
    z-index:0;
   
}


.blogImageContainer .after {
    position: absolute;
    top: 0;
    z-index:20;
    margin:70px 0px 0px 0px;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    color: #FFF;
    display: block;
    opacity:1;
    
}



.blogContainer {
    display:flex;
    justify-content:center
}


.blogArticleContainer{
    border:3px solid #0077FF;
}