.overlay {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0, 0.9);
    overflow-x: hidden;
    transition: 0.5s;
  }

  .menuItems li{
    list-style-type:none;
    margin:0px 0px 0px 60px;
    cursor: pointer;
  }

  .menuItems li:hover{
    color:#0077FF
  }

  .logoPosition{
      margin:0px 0px 0px 60px
  }
  
  .overlay-content {
    position: relative;
    top: 10%;
    width: 100%;
    text-align: center;
    margin-top: 30px;
  }
  
  .overlay a {
    padding: 8px;
    text-decoration: none;
    font-size: 36px;
    color: white;
    display: block;
    transition: 0.3s;
  }
  
  .overlay a:hover, .overlay a:focus {
    color: #f1f1f1;
  }
  
  .overlay .closebtn {
    position: absolute;
    top: 20px;
    right: 45px;
    font-size: 60px;
  }

  .activeMenu{
    background:#0066f5;
    font-size:5px;
    padding:0px 1px 1px 1px;
    border-radius:50%;
    color:#0066f5;

  }

  
  /* .peopleactiveMenu{
    background:#0066f5;
    font-size:5px;
    padding:0px px 1px 1px;
    border-radius:50%;
    color:#0066f5;
    margin-left:10px;

  } */

  .peopleactiveMenu{
    color:#0077FF
  }



  .notactiveMenu{
    display:none;
  }
  
  @media screen and (max-height: 450px) {
    .overlay a {font-size: 20px}
    .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
    }
  }



  @media screen and (max-width: 1024px){
      .largescreen{
          display:none
      }
      
  }



  @media screen and (min-width: 1025px){
  .togglePosition{
    margin:0px 0px 40px 100px
  }
}


.menuBackground{
  background:#111827;
  transition:600ms ease-in

}
