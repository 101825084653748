
@media screen and (max-width: 1023px){
.counterLabel{
  font-size:14px;
  width:50%;
  margin:auto
}

.counterNumber{
    font-size:40px;
    padding-top:30px
}

}


@media screen and (min-width: 1024px){
    .counterLabel{
      font-size:18px
    }
    
    .counterNumber{
        font-size:70px
    }
    
    }
    