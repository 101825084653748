

@media screen and (min-width:1024px){
    .imageContainer{
        position:relative
    }
    
    
    /* .imageContainer .after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: none;
        color: #FFF;
        display: block;
        opacity:1;
        background: linear-gradient(to bottom, rgba(255,0,0,0), rgb(0, 0, 0))
        
    }
     */
    
    
    .imageContainer .after .textPosition {
        position: absolute;
        bottom: 290px;
        left: 76px;
      }
    
      .imageContainer .after .textPosition .textSize{
        font-size:39px;
        width:70%
    }
    
}



/* mobile */
@media screen and (max-width:620px){

    .imageContainer .imageSize{
        height:700px;
        object-fit: cover;
    }

    .imageContainer .after .textPosition {
        position: absolute;
        top: 45%;
        
      }

      .imageContainer .after .textPosition .textSize{
        font-size:26px;
        display: flex;
        justify-content: center;
        text-align:center;
    }

    .imageContainer .after .textPosition .subtitle{
        font-size:14px;
        display:flex;
        justify-content: center;
        text-align:center
    }


}



@media screen and (min-width:521px) {
    
    
    .imageContainer .imageSize{
        height:700px;
        object-fit: cover;
    }

    .imageContainer .after .textPosition {
        position: absolute;
        bottom: 290px;
        left: 76px;
      }

      .imageContainer .after .textPosition .textSize{
        font-size:39px;
        width:70%
    }

    .imageContainer .after .textPosition .subtitle{
        font-size:14px;
    }


}