.App {
  font-family: sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding:0px 40px 0px 40px
}

.grid-item {
  border: 1px solid rgba(0, 0, 0, 0.8);
  font-size: 30px;
  text-align: center;
  height:200px;
  transition: 3s ease-in;
  box-shadow:0px 0px 0 0px rgba(0, 0, 0, 0.8);
  opacity:1;
  background: linear-gradient(226.03deg, rgba(255, 255, 255, 0.3) -115.11%, rgba(255, 255, 255, 0.1) 247.28%);
  border: 1px solid #CCCCCC;
  box-sizing: border-box;
  backdrop-filter: blur(20px);
  border-radius: 5px;
}

.grid-item:hover{
  opacity:0.1;
  box-shadow:0px 16px 32px 0px rgba(0, 0, 0, 0.8)

}

.seperator {
  width: 100%;
}

.controls-wrapper {
  width: 100%;
  background:red
}

.carousel-wrapper {
  width: 100%;
}

.rec-carousel-item:focus {
  outline: none;
  box-shadow: inset 0 0 1px 0px blue
}

  
  
button.rec-dot{
  background-color: #C4C4C4;
  border-color:#C4C4C4;
  box-shadow: 0 0 1px 3px #C4C4C4;
}

button.rec-dot:hover, button.rec-dot:active, button.rec-dot:focus  {
  box-shadow: 0 0 1px 3px #0077FF;
  background-color: #0077FF;
}


button .sc-bdvvtL .jRFJkP .rec .rec-arrow .rec .rec-arrow-right{
  background-color: #0077FF;
}


.rec-arrow-right{
  display:none;
  color:white;
  background:#0077FF
}

.rec-arrow-left{
  color:white;
  background:#0077FF;
  display:none;
}


@media screen and (min-width: 1024px){
  .grid-body{
    padding:40px 300px 40px 300px
  }
  

  
  .grid-container {
    display: grid;
    grid-template-columns: auto auto auto auto;
    padding: 10px;
  }
 
  .grid-item img{
    width:150px;
    margin:auto;
    height:100%
  }



  .titleSpace{
   display:none
  }
  
}



@media screen and (max-width: 1024px){
  .hidden-mobile{
    display:none
  }

  .titleSpace{
    margin-top:-190px;
    margin-bottom:-140px
  }
}


@media screen and (max-width: 1000px){
  .titleSpace{
    margin-top:-150px;
    margin-bottom:-100px
  }
}


@media screen and (max-width: 790px){
 
  .titleSpace{
    margin-top:-175px;
    margin-bottom:-100px
  }
}

@media screen and (max-width: 390px){
  .hidden-mobile{
    display:none
  }

  .titleSpace{
    margin-top:-180px;
    margin-bottom:-100px
  }
}