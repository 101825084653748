
@media screen and (min-width: 1024px) {
    input[type=text]{
        width: 326px;
        padding: 12px 20px;
        margin: 8px 0;
        display: inline-block;
        border: 1px solid #ccc;
        border-radius: 5px;
        box-sizing: border-box;
        background: linear-gradient(91.3deg, rgba(255, 255, 255, 0.6) -32.95%, rgba(255, 255, 255, 0.2) 125.26%);
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
      }
    
}



@media screen and (max-width: 1024px) {
    .referStartup{
        text-align:center !important
    }
    input[type=text]{
        padding: 12px 20px;
        width:90%;
        display: inline-block;
        border: 1px solid #ccc;
        border-radius: 5px;
        box-sizing: border-box;
        background: linear-gradient(91.3deg, rgba(255, 255, 255, 0.6) -32.95%, rgba(255, 255, 255, 0.2) 125.26%);
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
      }

      .sendy{
        display:flex;
        justify-content:center 
      }
    
}



.contactButton{
    border:1px solid #0077FF;
    padding: 16px;
    border-radius:10px
}