.slider{
    position:relative;
    height:100vh;
    display:flex;
    justify-content: center;
    align-items: center;
    z-index:1
}



.slider .right-arrow{
    position:absolute;
    top:50%;
    right:32px;
    font-size:3rem; 
    color:black;
    z-index:10;
    cursor: pointer;
    user-select: none;
}

.slider .right-arrow{
    position:absolute;
    top:50%;
    left:32px;
    font-size:3rem; 
    color:black;
    z-index:10;
    cursor: pointer;
    user-select: none;
}


.slide .active{
    position:relative;
    background:black;
    z-index:20
} 

.imageContainer{
    position:relative
}



.imageContainer .after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: none;
        color: #FFF;
        display: block;
        opacity:1;
        background: linear-gradient(to bottom, rgba(255,0,0,0), rgb(17,24,39))
        
    }


    .imageContainer .light {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: none;
        color: #FFF;
        display: block;
        background: linear-gradient(to bottom, rgba(62, 190, 250, 0.1), #0077FF)
        
    }
    

.navStyle{
    position:fixed;
    z-index:40;
    width:100%
}

.carouselStyle{
    position:fixed;
    z-index:-1
}

.slide{
  background: linear-gradient(to bottom, rgba(255,0,0,0), rgb(17,24,39))
}



.textStyle{
    position: absolute;
    bottom: 40%;
    left: 50%;
    width:60%;
    transform: translate(-50%);
}

.textSize{
    font-size:48px;
    width:100%;
}


.carouselController {
    position: fixed;
    bottom: 0;
    left: 16px;
    margin:0px 0px 90px 90px;
    z-index:50
  }
#controllerVisible{
    display:none
}

  .vl {
    border-left: 0.5px solid grey;
    height: 96px;
    margin:0px 0px 0px 6px
  }

  .circle{
      border:1px solid grey;
      padding:5px;
      border-radius:50%;
      margin:9px 20px 0px 0px
  }

  .circle:hover{
    background:white
}

  .circle:active{
      background:white
  }

  .active{
    background:white
  }


  .buttonside{
    display:flex
  }
  




@media screen and (max-width: 1023px){
    .textSize{
            font-size:35px;
            padding:0px -50px 0px 100px
    }

    .textStyle{
        position: absolute;
        bottom: 35%;
        left: 50%;
        transform: translate(-50%);
       
    }

    .carouselController {
        position: absolute;
        top: 8px;
        left: 16px;
        margin:200px 0px 0px 0px
      }
    
      .vl {
        border-left: 0.5px solid white;
        height: 100px;
        margin:0px 0px 0px 6px
      }

      .circle{
        border:1px solid grey;
        padding:5px;
        border-radius:50%;
        margin:9px 20px 0px 0px
    }

      .circle:hover{
        background:white
    }

      .circle:active{
          background:white
      }

      .image{
        object-fit:cover;
        height:700px;
        position:relative;
      }

      

      
      .slider{
        position:relative;
    }

}


.slideAnimation {
    opacity:0;
    transition-duration:1s ease
}

.slideAnimation.active{
    opacity:1;
    transition-duration:1s;
    transform: scale(1.08)
}



.fade-in-image {
  animation: fadeIn 3s;
  -webkit-animation: fadeIn 3s;
  -moz-animation: fadeIn 3s;
  -o-animation: fadeIn 3s;
  -ms-animation: fadeIn 3s;
}
@keyframes fadeIn {
  0% {opacity:0.5;}
  100% {opacity:1;}
}

@-moz-keyframes fadeIn {
  0% {opacity:0.5;}
  100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
  0% {opacity:0.5;}
  100% {opacity:1;}
}

@-o-keyframes fadeIn {
  0% {opacity:0.5;}
  100% {opacity:1;}
}

@-ms-keyframes fadeIn {
  0% {opacity:0.5;}
  100% {opacity:1;}
}


.fadeIn{
 animation:fadeIn 4s infinite
}

@keyframes fadeIn { 
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}






figure {
	margin-bottom: 60px;
}

/* img tags */
.imagesContainer {
	width: 500px;
	height: 280px;
	position: relative;
	overflow: hidden;
	box-shadow: rgba(0,0,0,0.2) 10px 10px 10px;
}

.imagesContainer img {
	width: 500px;
	position: absolute;
	top: 0;
	left: 0;
}

/* images using CSS */
.imageDiv {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-size: cover;
}

.image1 { background-image: url('../../assets/images/olumide-bamgbelu-Ciba8rvHYng-unsplash.jpg'); }

.image2 { background-image: url('../../assets/images/namnso-ukpanah-6UqJTfoXIq8-unsplash.jpg'); }

.image3 { background-image: url('../../assets/images/ben-iwara-FHo82ziqAaQ-unsplash.jpg'); }


/* animation styles */
@keyframes fadeIn {
	0% { opacity: 0; }
	20% { opacity: 0; }
	60% { opacity: 1; }
	100% { opacity: 1; }
}

.fadeInClass {
	animation-name: fadeIn;
	animation-duration: 5s;
	animation-iteration-count: infinite;
	animation-direction: alternate;
	animation-timing-function: linear;
}


@media screen and (max-width:654px){
  .buttonside{
    display:block
  }
  .textSize{
    font-size:30px;
    padding:0px -50px 0px 50px
}

.textStyle{
  position: absolute;
  top: 32%;
  left: 50%;
  width:60%;
  transform: translate(-50%);
}

}



@media screen and (max-width:300px){
  .textStyle{
    position: absolute;
    top: 30%;
    left: 50%;
    width:60%;
    transform: translate(-50%);
}

.textSize{
  font-size:26px;
  width:90%;
}

}