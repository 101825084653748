

@media screen and (min-width:1024px){
    .imageContainer .people{
        position:relative
    }
    
    
    .imageContainer .people .after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: none;
        color: #FFF;
        display: block;
        opacity:1;
        background: linear-gradient(to bottom, rgba(255,0,0,0), rgb(0, 0, 0))
        
    }
    
    
    
    .imageContainer .people .after .textPosition {
        position: absolute;
        bottom: 290px;
        left: 76px;
      }
    
      .imageContainer .people .after .textPosition .title{
        font-size:39px;
        width:70%
    }
    
}



/* mobile */
@media screen and (max-width:620px){

    .imageContainer .people .imageSize{
        height:700px;
        object-fit: cover;
    }

    .imageContainer .people .after .textPosition {
        position: absolute;
        top: 40%;
        
      }

      .imageContainer .people .after .textPosition .title{
        font-size:21px;
        margin:20px;
        display: flex;
        justify-content: center;
        text-align:center;
    }

 .imageContainer .people .after .textPosition .subtitle{
        font-size:14px;
        display:flex;
        justify-content: center;
        text-align:center
    }


}



@media screen and (min-width:521px) {
  .imageContainer .people .imageSize{
        height:700px;
        object-fit: cover;
    }

    .imageContainer .people .after .textPosition {
        position: absolute;
        bottom: 290px;
        left: 76px;
      }

     .imageContainer .people .after .textPosition .textSize{
        font-size:20px;
        width:70%
    }

 .imageContainer .people .after .textPosition .subtitle{
        font-size:14px;
    }


}