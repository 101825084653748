@media screen and (min-width:1024px){
    .gridContainer{
        margin-bottom:70px
    }
}




@media screen and (max-width:1023px){
    .gridContainer{
        margin-bottom:70px
    }
}


