:root {
    --display: none;
    --iconPosition:0px
  }

.button{
    padding:16px 32px 16px 32px;
    background: #0077FF;
    color:white;
    border-radius:10px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}


.buttonOutline{
    padding:16px 32px 16px 32px;
    border:1px solid #0077FF;
    color:white;
    border-radius:10px
}

.button:hover{
    background:black;
    box-shadow: 0 16px 32px 0 rgba(0,0,0,0.2);
}

.buttonOutline:hover{
    background:#0077FF;
    box-shadow: 0 16px 32px 0 rgba(0,0,0,0.2);
}


.buttonCircle{
    padding:15px;
    background: #0077FF;
    color:white;
    font-size:20px;
    border-radius:50%;
    transition:display 2s;
}


.buttonArrow{
    padding:20px;
    background: #0077FF;
    color:white;
    font-size:20px;
    border-radius:50%;
    transition:display 2s;
}

.circleHover {
    display:var(--display);
    transition:display 2s
}

.iconPosition{
    padding:var(--iconPosition) 0px 0px var(--iconPosition)
}

.buttonCircle:hover{
    /* box-shadow:0px 16px 32px 0px rgba(0,0,0,0.2); */
    color:white;
    font-size:20px;
    border-radius:30px;
    --display:block;
    --iconPosition:6px
}


.buttonRound{
    border-radius:20px;
    background:#0077FF;
    padding:10px 35px 10px 35px;
    color:white
}

.buttonRound:hover{
    color:#0077FF;
    background:white
}



@media screen and (max-width: 1024px){

    .button{
        padding:16px 32px 16px 32px;
        background: #0077FF;
        color:white;
        border-radius:10px;
        width:100%
    }

    .button:hover{
        box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    }
    
    
    .buttonOutline{
        padding:16px 32px 16px 32px;
        border:1px solid #0077FF;
        color:white;
        border-radius:10px;
        width:100%
    }

   

    .buttonCircle{
        padding:20px;
        background: #0077FF;
        color:white;
        font-size:20px;
        border-radius:50%
    }

    .circleHover {
        display:var(--display)
    }

    .iconPosition{
        padding:var(--iconPosition) 0px 0px var(--iconPosition)
    }

    .buttonCircle:hover{
        padding:20px;
        background:#0077FF;
        box-shadow:0px 16px 32px 0px rgba(0,0,0,0.2);
        color:white;
        font-size:20px;
        border-radius:30px;
        --display:block;
        --iconPosition:6px
    }

  }