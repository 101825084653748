.contact .featureContainer{
    display:block;

}


.contact .featureBox{
    border:3px solid #0077FF;
    width:80%;
    height:80%;
    padding:40px 20px 40px 20px;
    margin:130px auto 0px auto

}
.contact .buttonPosition{
    margin:30px 0px 30px 0px
}


.contact .featureTitle{
    font-size:20px;
    margin-top:-53px;
    margin-bottom:10px
}

.contact .featureText{
    font-size:18px
}

@media screen and (min-width: 1024px){
    .contact .featureBox{
        border:2px solid #0077FF;
        width:500px;
        height:80%;
        
        margin:130px 50px 0px 50px
    
    }

    .contact .featureTitle{
        font-size:20px;
        margin-top:-53px;
        margin-bottom:60px
    }

     .featureContainer{
        display: grid;
        grid-template-columns: auto auto;
        padding: 0px;
}
  }