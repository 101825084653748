.centerImg{
    margin:auto;
    width:90%
}

.aboutText{
    width:80%;
    margin:30px auto 30px auto ;
    text-align:center;
    padding:0px 0px 30px 0px
}

.kickoffAdvantage{
    margin-top:140px;
}


.imageRotateHorizontal{
    border-radius:50%
}

/* .imageRotateHorizontal{
    -moz-animation: spinHorizontal .9s infinite linear;
    -o-animation: spinHorizontal .9s infinite linear;    
    -webkit-animation: spinHorizontal .9s infinite linear;
    animation: spinHorizontal .9s infinite linear;
}

@keyframes spinHorizontal {
    0% { transform: rotateY(0deg); }
    100% { transform: rotateY(360deg); }
} */


@media screen and (min-width: 1024px){
    .aboutContainer{
        display:flex
    }
    .globeSize{
        width:900px;
        height:900px;
        margin-left:-240px
    }

    .aboutText{
        width:80%;
        margin:100px 130px 30px -230px ;
        text-align:start;
        padding:200px 190px 0px 140px
    }

    .aboutText div{
        font-size:18px;
        font-weight:300;
    }

    .featuresContainerFlex{
        display:flex
    }

    .counterContainer{
        display: flex;
        margin:60px 10px 0px 10px
    }

}


@media screen and (max-width: 1023px){
  .aboutText .aboutButton{
      display:flex;
      justify-content: center;
    }

    .centerImg{
       display:flex;
       justify-content: center;
    }

    .counterContainer{
        width:100%
    }


}

/* 
.Count {
    
} */
