.homefeatureContainer{
    display:flex;
    justify-content: center;
    margin:50px 0px 30px 0px
}

.featureBox{
    border:2px solid #0077FF;
    width:80%;
    height:80%;
    padding:40px 20px 40px 20px
}

.featureTitle{
    font-size:29px;
    margin-bottom:10px
}

.featureText{
    font-size:16px
}

@media screen and (min-width: 1023px){
    .featureBox{
        border:2px solid #0077FF;
        width:80%;
        height:340px;
        padding:40px 50px 380px 50px
    }
    .featureText{
        font-size:16px;
    }
  }