@media screen and (max-width:600px){
    .tellEm{
        margin-top: -50px;
        padding-top:50px
    }

    .mobilemarginTop{
        margin:17px 0px 0px 0px
    }
}
